/**
 * 
 */

export default {

	data : function() { return {

		loaded	: false,

		users_vue	: [],
		best_user	: false,

	}},

	created	: function () {
	},

	methods	: {

		/**
		 * Tri des joueurs selon une donnée
		 * @params object sorting
		 */

		sort( sorting ) {

			return this.users_vue.slice().sort( ( a , b ) => {

				var current	= sorting.field
				
				var a_val	= parseFloat( a.sorting_values[current] )
				var b_val	= parseFloat( b.sorting_values[current] )

				let modifier	= 1

				if ( sorting.dir === 'desc' ) modifier = -1

				if ( a_val < b_val ) return -1 * modifier
				if ( a_val > b_val ) return 1 * modifier

				return 0

			})

		},

		/**
		 */

		update_table() {

			var _this	= this
			var higher_pts	= 0

			// _this.loaded	= true

			_this.users_vue	= []
			_this.set_ranking_datas()



			/**
			 * Formatage des données utiles
			 */

			_this.$store.state.team.users.forEach(function( user ) {
				_this.set_user_datas( user )
			})

			/**
			 * Player of the month
			 */

			_this.users_vue.forEach(function( user ) {

				if ( !_this.best_user || _this.best_user.week_score < user.week_score ) {
					_this.best_user	= user
				}

				if ( higher_pts < user.week_score ) higher_pts = user.week_score
				if ( higher_pts < user.week_estimation ) higher_pts = user.week_estimation

			})

			_this.best_user.deck_bp	= 0
			_this.best_user.deck_picks.forEach(function( pick ) {

				if ( pick.score > _this.best_user.deck_bp ) {
					_this.best_user.deck_bp	= pick.score
				}

			})

			_this.users_vue.forEach(function( user ) {

				user.higher	= higher_pts
				user.best_picks	= _this.user_total_bestpicks( user )

			})

			_this.set_deck_datas()

		},

		/**
		 */

		set_user_datas( user ) {

			var _this	= this
			var user_tmp	= user
			var pick_scores	= []

			if ( !user_tmp.rankings ) return false

			user_tmp.deck_picks	= []
			user_tmp.week_score	= 0
			user_tmp.week_estimation	= 0
			user_tmp.sorting_values	= {
				ranking	: false,
				week_score	: 0,
			}

			/**
			 * Récupération de tous les picks du
			* joueur
			 */

			_this.deck.picks.forEach(function( pick ) {

				if ( pick.user_id == user_tmp.id ) {

					pick.score	= parseInt( pick.score )
					pick.estimation	= parseInt( pick.estimation )

					user_tmp.week_score	+= pick.score
					user_tmp.week_estimation	+= pick.estimation
					user_tmp.rungood	= user.week_score - user.week_estimation
					pick_scores.push( pick.score )
					user_tmp.deck_picks.push( pick )

				}

			})
			
			user_tmp.week_avg	= pick_scores.reduce(( total, score ) => total + score, 0 ) / pick_scores.length

			user_tmp.deck_ranking	= user_tmp.get_ranking( _this.date_end.format( 'YYYY-MM-DD' ) )
			var ldeck_ranking	= user_tmp.get_ranking( _this.ldeck_end_date.format( 'YYYY-MM-DD' ) )

			if ( !user_tmp.deck_ranking ) user_tmp.deck_ranking	= { value : 0 }
			if ( !ldeck_ranking ) ldeck_ranking	= { value : 0 }

			user_tmp.ranking_evo	= ldeck_ranking.value - user_tmp.deck_ranking.value
			user_tmp.ranking_evo	= ( user_tmp.ranking_evo > 0 ) ? '+' + user_tmp.ranking_evo : user_tmp.ranking_evo

			user_tmp.sorting_values.week_score	= user_tmp.week_score
			user_tmp.sorting_values.ranking	= user_tmp.deck_ranking.value

			this.users_vue.push( user_tmp )
			
		},

		/**
		 * Rankings datas
		 */

		set_ranking_datas() {

			// var last_sunday	= this.$moment( this.date_start.valueOf() ).subtract( 1, 'days' )

			this.deck_ranking	= this.rankings.find( ranking => ranking.date == this.date_end.format( 'YYYY-MM-DD' ) )
			var ldeck_ranking	= this.rankings.find( ranking => ranking.date == this.ldeck_end_date.format( 'YYYY-MM-DD' ) )

			if ( !this.deck_ranking ) this.deck_ranking	= { ranking : 0 }
			if ( !ldeck_ranking ) ldeck_ranking	= { ranking : 0 }

			this.evo	= ldeck_ranking.ranking - this.deck_ranking.ranking
			this.deck_ranking	= this.deck_ranking.ranking

		},

		/**
		 * Données du deck
		 */

		set_deck_datas() {

			this.deck.set_days_averages()

			if ( this.last_deck.picks && this.last_deck.picks.length > 0 ) {
				this.last_deck.set_days_averages()
			}

			this.deck_rungood	= this.deck.pts_avg - this.deck.estim_avg
			this.deck_higher	= this.deck.estim_avg >= this.deck.pts_avg ? this.deck.estim_avg : this.deck.pts_avg
			this.deck_higher	= this.last_deck.pts_avg >= this.deck_higher ? this.last_deck.pts_avg  : this.deck_higher
		
		},

		/**
		 */

		user_total_bestpicks( user ) {

			var _this	= this
			var total	= 0
			var this_week	= 0

			user.picks.forEach(function( pick ) {

				var date	= _this.$moment( pick.date )

				if ( pick.best_pick == 1 ) {

					total++

					if ( date.valueOf() >= _this.date_start.valueOf() && date.valueOf() <= _this.date_end.valueOf() ) {
						this_week++
					}

				}

			})

			return { week : this_week, total : total }

		},

	},

	watch	: {

		loaded() {

			this.$emit( 'set_loaded', true )

			// this.load	= false
			this.update_table()

		},

	},

	mounted	: function() {
	},

}